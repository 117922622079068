import React, { useState } from 'react';
// import Chat from "../Chat";
import './Contactus.scss';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

function Contactus() {
  const [is_headquarters, setHeadquarters] = useState(true);
  const [is_international, setInternational] = useState(false);
  const changeAddress = (name) => {
    if (name === 'Headquarters') {
      setHeadquarters(true);
      setInternational(false);
    } else {
      setHeadquarters(false);
      setInternational(true);
    }
  }

  return (
    <>
      <section>
        {/* <Chat /> */}
        <div className='Contactus'>
          <div className='col-12 d-flex mobileflex'>
            <div className='col-12 col-sm-12 col-md-12 col-xl-6 col-lg LeftContact'>
              <div className='Address pb-4' onClick={e => { changeAddress('Headquarters') }}>
                <h4>Headquarters
                  <i className={"mapicon " + (is_headquarters ? 'activecolor' : 'deactivecolor')}><FontAwesomeIcon icon={['fas', 'map-marker-alt']} /></i>
                </h4>
                <div className='add d-flex'>
                  <i className=''><FontAwesomeIcon icon={['fas', 'map-marked-alt']} /></i>
                  <span>909−2220 Lake Shore Blvd West<br></br>
                    Toronto, ON M8V 0C1<br></br>
                    Canada</span>
                </div>
                <div className='add d-flex'>
                  <i className=''><FontAwesomeIcon icon={['fas', 'phone']} /></i>
                  <span>Tel : +1 ( 437 ) 779-7715 </span>
                </div>
              </div>
              {/* <div className='Address' onClick={e => { changeAddress('International') }}>
                <h4>International Office
                  <i className={"mapicon " + (is_international ? 'activecolor' : 'deactivecolor')}><FontAwesomeIcon icon={['fas', 'map-marker-alt']} /></i>
                </h4>
                <div className='add d-flex'>
                  <i className=''><FontAwesomeIcon icon={['fas', 'map-marked-alt']} /></i>
                  <span>
                    Armada 2, Unit 1509<br></br>
                    JLT, Cluster P<br></br>
                    Dubai<br></br>
                    UAE
                  </span>
                </div>
                <div className='add d-flex'>
                  <i className=''><FontAwesomeIcon icon={['fas', 'phone']} /></i>
                  <span>Tel : +971 ( 4 ) 399 8335</span>
                </div>
              </div> */}
              <div className='Address' onClick={e => { changeAddress('International') }}>
              <div className='add d-flex'>
                  <span><em><a href="mailto:sales@flowmed.ca">sales@flowmed.ca</a></em></span>
                </div>
              </div>
              <div className="socialMedia">
                <a className="youtube" href="https://www.youtube.com/channel/UC1jrl4j8Dsy-3YZHX1VVdsw" target="_blank" rel="noreferrer">
                  <i className=''><FontAwesomeIcon icon={['fab', 'youtube']} /></i>
                </a>

                {/* <a className="twitter" href="https://twitter.com/cortex_my" target="_blank">
                  <i className=''><FontAwesomeIcon icon={['fab', 'twitter']} /></i>
                </a> */}

                <a className="linkedin" href="https://www.linkedin.com/company/mycortex/?viewAsMember=true" target="_blank" rel="noreferrer">
                  <i className=''><FontAwesomeIcon icon={['fab', 'linkedin-in']} /></i>
                </a>

                <a className="facebook" href="https://www.facebook.com/MyCortex-100503544993319/?modal=admin_todo_tour" target="_blank" rel="noreferrer">
                  <i className=''><FontAwesomeIcon icon={['fab', 'facebook']} /></i>
                </a>
              </div>
            </div>
            <div className='col-12 col-sm-12 col-md-12 col-xl-6 col-lg p-3 Rightcontact'>
              <div id="map">
                {is_headquarters &&
                  <iframe className="gMap" title='headquarters'
                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2888.2191847329404!2d-79.48554008450321!3d43.622796779122325!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x882b3757439aacf3%3A0x3f1e2e3229cd3536!2sFlowMed!5e0!3m2!1sen!2sin!4v1592990524942!5m2!1sen!2sin"
                    frameBorder="0" allowFullScreen="" aria-hidden="false"></iframe>
                }
                {is_international &&
                  <iframe className="gMap" title="international"
                  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3613.79983347264!2d55.1432844149034!3d25.074772642809993!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3e5f6ca92f5fcee7%3A0x329361d126563107!2sArmada%20Tower%202%20-%20Jumeirah%20Lakes%20Towers%2C%20Cluster%20P%20-%20Sheikh%20Zayed%20Rd%20-%20Dubai%20-%20United%20Arab%20Emirates!5e0!3m2!1sen!2sin!4v1587554875531!5m2!1sen!2sin"
                    frameBorder="0" allowFullScreen="" aria-hidden="false"></iframe>
                }
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Contactus;
