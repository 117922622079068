import React from "react";
import "./solution.scss";
import usecase1 from "../../images/banner/usecase1.png";
import usecase2 from "../../images/banner/usecase2.png";
import usecase3 from "../../images/banner/usecase3.png";
import usecase4 from "../../images/banner/usecase4.png";
// import leader2 from "../../images/banner/leader2.png";
// import leader3 from "../../images/banner/leader3.png";
// import leader4 from "../../images/banner/leader4.png";
// import leader5 from "../../images/banner/leader5.png";
// import leader6 from "../../images/banner/leader6.png";

// const listData = [
//   {
//     background: leader1,
//     head: 'Scalability',
//     description: 'World-class database design for handling millions of patient records.'
//   },
//   {
//     background: leader2,
//     head: 'Collaboration',
//     description: 'Digitized clinical workflow enabling Healthcare professionals collaborate more efficiently.'
//   },
//   {
//     background: leader3,
//     head: 'Preset Protocols',
//     description: 'Manage your population health through a patient specific preset lifestyle and medical protocols.'
//   },
//   {
//     background: leader4,
//     head: 'Quick Service Turnup',
//     description: 'Launch your virtual healthcare and population health management services in less than a week with easy to manage subscription.'
//   },
//   {
//     background: leader5,
//     head: 'Appointments & Payments',
//     description: 'Integrated appointment and payment modules via patient’s smart devices.'
//   },
//   {
//     background: leader6,
//     head: 'Cost Effective',
//     description: 'HIVE - our home tablet will help you simplify the whole family engagement with health providers.'
//   },

// ];

function solutionList() {
  return (
    <div className="solutionlist">
      <section className=" solutionbg-color">
        <div className="col-12 sec-split">
          <div className="flex col-12 col-sm-12 col-md-12 col-xl-7 col-lg-8">
            <div className="left-flex">
              <h1 className="title is-1 sec-one">
                Scalable and Adaptable to Suit Your Needs
              </h1>
              <h1 className="title is-1 sec-one">
                Keep Track Of Your Wellbeing on the go{" "}
              </h1>
              <p className="sec-two">
                Hive by FlowMED is a cutting-edge platform that provides a
                virtual collaboration solution to serve your remote patient
                monitoring requirements for early detection, preventative
                measures, and individual wellness. This is simply achieved
                through an open architecture of integrating seamlessly with
                off-the-shelf bluetooth medical and lifestyle sensors and with
                your EMR data. Hive Virtual consultation module (HiveMeet)
                empowers health care professionals to rapidly engage with
                patients through a real-time audio/video session and receive
                live medical data during the encounter.
                {/* FlowMed is a cutting-edge platform that provides a virtual collaboration solution to serve your population health requirements of early detection, preventative measures, and individual wellness. This is done easily by integrating seamlessly with your EMR data.  Our Virtual consultation module empowers health care professionals rapidly communicate with patients for a live encounter. */}
              </p>
              <form className="contactPageForm">
                <div className="col-12 formBoxSection d-flex p-0">
                  <a href="/contact-us" className="btn contactpageBtn">
                    ENQUIRE
                  </a>
                </div>
              </form>
            </div>
          </div>
          <div className="col-5 right-flex">
            <span className="sec-split-two">
              {/* <img src={HomeBanner} /> */}
            </span>
          </div>
        </div>
      </section>

      <section className="solutionleader">
        <header>
          <h2> FlowMed use case</h2>
        </header>
        <div className="row listcontainer">
          <div className="col-sm-12 col-md-6 col-lg-6 col-xl-6">
            <div className="usecaselist">
              <p>
                <img src={usecase1} alt="usecase1" />
              </p>
              <h2> Covid 19</h2>
              <p className="img_contant">
                FlowMed helped monitor and treat Covid-19 patients at Universal
                Hospital, Al Ain during the initial outbreak. Patients
                communicated with virtually doctors through the application.
                Their vitals were monitored by doctors through compatible
                devices. The integration with Concept EMR helped avoid duplicate
                entries.
              </p>
            </div>
          </div>
          <div className="col-sm-12 col-md-6 col-lg-6 col-xl-6">
            <div className="usecaselist">
              <p>
                <img src={usecase2} alt="usecase2" />
              </p>
              <h2> EMCA Homecare</h2>
              <p className="img_contant">
                Emirati Canadian Home Health Care Services (EMCA), Abu Dhabi
                used telemonitoring and teleconsulting to treat homecare
                patients. Caregivers of bed-ridden patients used the app to
                enter the vital signs. It helped doctors monitor patients
                remotely and suggest treatment measures. Video calls helped
                patients communicate with doctors regarding advanced issues.{" "}
              </p>
            </div>
          </div>
          <div className="col-sm-12 col-md-6 col-lg-6 col-xl-6">
            <div className="usecaselist">
              <p>
                <img src={usecase3} alt="usecase3" />
              </p>
              <h2> Chronic disease monitoring</h2>
              <p className="img_contant">
                Healthcare providers at Royal Health Heart Center (RHHC), Al Ain
                tele-consulted chronically ill and elderly patients, while also
                providing lifestyle education to enhance their wellbeing.
                Prescription refills through the app helped patients avoid
                visiting the center.{" "}
              </p>
            </div>
          </div>
          <div className="col-sm-12 col-md-6 col-lg-6 col-xl-6">
            <div className="usecaselist">
              <p>
                <img src={usecase4} alt="usecase4" />
              </p>
              <h2> Sports Medicine consultation</h2>
              <p className="img_contant">
                Czech Rehabilitation Hospital (CRH), Al Ain tele-consulted
                patients and offered sports medicine consultations to athletes.
                Doctors leveraged integrated wearables of athletes like Google
                Fit to collect their lifestyle data. Regular video calls helped
                doctors monitor the progress of their patients.
              </p>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default solutionList;
